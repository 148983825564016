import React from 'react';
import styled from'styled-components'

const WorkSectionWrapper =  styled.div`
    padding-top: 60px;
`



export {WorkSectionWrapper}

